import React from 'react'
import Layout from '../../components/layouts'
import Walkthrough from '../../images/svgs/Walkthrough'

function walkthrough() {
    return (
        <Layout>
        <div className="walk_contain">
          <section className="walk" style={{position: 'relative'}}>
  
           
             <Walkthrough/>
  
  
          </section>
        </div>
      </Layout>
    )
}

export default walkthrough
